<template>
    <div class="c_content_container contact">
        <!-- 상단배너 START -->
        <section class="c_section banner banner_about">
            <div class="about">
                <div class="c_inner">
                    <div class="video_wrapper">
                        <div class="content">
                            <iframe
                                src="https://www.youtube.com/embed/dxWJHCzvBCM?autoplay=1&mute=1&loop=1&playlist=dxWJHCzvBCM"
                                title="창업플랫폼 홍보영상"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; loop"
                                allowfullscreen
                            ></iframe>
                        </div>
                    </div>
                    <div class="text_wrapper">
                        <h1>
                            위아오너를 이용해 보세요! <br />
                            <span>창업이 쉬워집니다.</span>
                        </h1>
                        <p>
                            물품 뿐만 아니라 인테리어, 부동산, 보험 등 모든
                            정보까지 얻을 수 있어요! 위아오너로 사장님의 꿈을
                            바로 실현시키고 공간을 꾸며보세요!
                        </p>
                        <div class="btn_wrapper">
                            <a
                                href="https://play.google.com/store/apps/details?id=com.dever.weareowner"
                            >
                                <img
                                    src="@/assets/images/common/btn_google.svg"
                                />
                            </a>
                            <a
                                href="https://apps.apple.com/kr/app/%EC%9C%84%EC%95%84%EC%98%A4%EB%84%88/id1585476966"
                            >
                                <img
                                    src="@/assets/images/common/btn_app_store.svg"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- 상단배너 END -->

        <section class="c_section about_content">
            <div class="c_inner">
                <h2 v-textMotion>
                    <span class="textMotion_target">CEO 인사말</span>
                </h2>
                <div class="about_intro">
                    <div
                        class="video_wrapper"
                        v-slidein:reverse="{ percent: '-10%', threshold: 0.1 }"
                    >
                        <div class="content">
                            <iframe
                                src="https://www.youtube.com/embed/bOCfi4-yCTs?autoplay=1&mute=1&loop=1&playlist=bOCfi4-yCTs"
                                title="[머니투데이방송 비즈왕] 위아오너 - 원스톱 창업 플랫폼"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                            ></iframe>
                        </div>
                    </div>
                    <div class="right" v-slidein:reverse="{ percent: '10%' }">
                        <p>안녕하세요 (주)위아오너 노상호입니다.<br /></p>
                        <p>
                            빠르게 변화하고 있는 창업 시장에서 창업자들의 고충을
                            해결하고자 원스톱 창업 플랫폼 '위아오너'를
                            출시하였습니다.
                        </p>
                        <p>
                            길을 걷다 문득 '이 가게는 오픈하는데 얼마나
                            들었을까?'라는 궁금증을 가진 예비 창업자들을 위해
                            'AI 창업 견적 솔루션'을 개발했습니다.
                        </p>
                        <p>
                            위아오너는 무엇부터 해야할지 모르는 막연한
                            사장님들을 위해 시작과 성공의 해결책을 제시하며
                            끝까지 함께합니다.
                        </p>
                        <p>
                            조직구성원들이 각자의 위치에서 기업가치를 기반으로
                            지속가능한 목표를 체계적으로 이행할 수 있도록
                            서포트하여, 조화롭게 성장하는 회사가 될 수 있도록
                            이바지하겠습니다.
                        </p>
                        <p>앞으로도 많은 관심과 격려 부탁드립니다.</p>
                        <p>
                            함께 성장하고 싶은 회사, 회사구성원들 모두 일하고
                            싶어하는 위아오너를 만들기 위한 새로운 도약을
                            다짐합니다.
                        </p>
                        <p class="sign">
                            <span>주식회사 위아오너 대표이사</span>
                            <img src="@/assets/images/about/sign.svg" />
                        </p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {};
</script>

<style scoped src="@/assets/css/layout.css"></style>
<style scoped src="@/assets/css/about.css"></style>
